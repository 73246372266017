import { render, staticRenderFns } from "./AppicXeroCostOfSalesComparison.vue?vue&type=template&id=747a564e&scoped=true&"
import script from "./AppicXeroCostOfSalesComparison.vue?vue&type=script&lang=js&"
export * from "./AppicXeroCostOfSalesComparison.vue?vue&type=script&lang=js&"
import style0 from "./AppicXeroCostOfSalesComparison.vue?vue&type=style&index=0&id=747a564e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "747a564e",
  null
  
)

export default component.exports